// @ts-nocheck

import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import { Link } from "react-router-dom";
import { chat } from "../../../apis/assistant";
import "./prediction.css";
import waiterLogo from "./waitIcon.svg";

function getLinkPreviewInfo(url: string) {
	const fetchData = async () => {
		try {
			const response = await fetch(url);
			const data = await response.text();
			const parser = new DOMParser();
			const doc = parser.parseFromString(data, "text/html");
			const title = doc.querySelector("title")?.textContent || "";
			const description =
				doc.querySelector('meta[name="description"]')?.getAttribute("content") || "";
			const image =
				doc.querySelector('meta[property="og:image"]')?.getAttribute("content") || "";

			return { title, description, image, url };
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	return fetchData();
}

export const Prediction = (props: any) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [prediction, setPrediction] = useState<any>(null);
	const [linkPreviews, setLinkPreviews] = useState<[]>([]);

	useEffect(() => {
		setIsLoading(true);
		/*
        if (props.messages[i].message === "Please wait a moment while I generate a summary of your meeting.") {
            setPrediction(null);
            setLinkPreviews([]);
            setIsLoading(true);
            return;
        }
        */
	}, []);

	useEffect(() => {
		if (!isLoading) {
			scrollToBottom();
			return;
		}
		var inMemoryArray = [];
		props.messages.toReversed().map((m: any, index: number) => {
			let lookAtMsg = "";
			if (inMemoryArray.length >= 6 || !m.inMemory) {
				return;
			}
			if (m.type === "bot") {
				lookAtMsg = m.generatedMessage;
			} else {
				lookAtMsg = m.message;
			}
			if (lookAtMsg === props.sentence) {
				return;
			}

			inMemoryArray.push({
				author: m.type === "bot" ? "assistant" : "user",
				content: m.type === "bot" ? m.generatedMessage.answer : m.message,
			});
		});
		chat(props.sentence, inMemoryArray, props?.aiTour)
			.then((res: any) => {
				props.setBotMessageCallback(res.data);
				setPrediction(res.data);
				setLinkPreviews(
					res.data.citationMetadata[0].citations.map((citation: any) => {
						var values = getLinkPreviewInfo(citation.url);
						if (values) {
							return values;
						}
					})
				);
				setIsLoading(false);
				scrollToBottom();
			})
			.catch((err: any) => {
				console.log(err);
				setIsLoading(false);
			});
	}, [isLoading]);

	const scrollToBottom = () => {
		if (props.messagesEndRef.current === null) {
			return;
		}
		props.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: "transparent",
					fontSize: "13.3333px",
					fontWeight: "400",
					px: "14px",
					py: "10px",
					borderRadius: "5px",
				}}
			>
				{isLoading && (
					<Box sx={{ display: "flex" }}>
						<img
							src={waiterLogo}
							alt="waiter"
							style={{
								width: "30px",
								height: "30px",
								margin: "auto",
							}}
						/>
					</Box>
				)}
				{prediction && (
					<>
						<Typography
							sx={{
								fontSize: "13.3333px",
								textAlign: "justify",
							}}
						>
							<Markdown
								rehypePlugins={[rehypeRaw]}
								remarkPlugins={[remarkGfm]}
								components={{
									sup: ({ node, ...props }) => <sup {...props} />,
								}}
							>
								{prediction.answer}
							</Markdown>
						</Typography>
						{prediction?.sources?.length > 0 && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									marginTop: "10px",
									width: "100%",
									color: "black !important",
								}}
							>
								<Typography
									variant="body2"
									color="textSecondary"
									component="p"
									sx={{
										fontSize: "13.3333px",
										fontStyle: "italic",
										fontWeight: "bold",
										margin: "auto",
									}}
								>
									Sources ({prediction.sources.length})
								</Typography>
								{prediction.sources.map((source: any, index: number) => {
									return (
										<Paper
											key={index}
											sx={{
												maxWidth: "100%",
												display: "flex",
												flexDirection: "row",
												p: "5px",
												m: "2px",
												alignContent: "center",
												justifyContent: "center",
												"&:hover": {
													backgroundColor: "#f0f0f0",
												},
												border: "1px solid #a5a5a5",
												borderRadius: "5px",
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													justifyContent: "center",
													width: "20px",
													height: "20px",
													borderRadius: "5px",
													backgroundColor: "#f0f0f0",
													fontSize: "10px",
													color: "black",
													fontWeight: "bold",
													border: "1px solid #a5a5a5",
													margin: "auto",
												}}
											>
												<Typography
													variant="body2"
													component="p"
													sx={{
														fontSize: "10px",
														margin: "auto",
													}}
												>
													{index + 1}
												</Typography>
											</Box>
											<Link
												href={source.url}
												target="_blank"
												variant="body2"
												sx={{
													fontSize: "13.3333px",
													marginLeft: "5px",
													wordBreak: "break-all",
													"@media (max-width: 600px)": {
														fontSize: "12px",
													},
													width: "100%",
												}}
											>
												{source.title}
											</Link>
										</Paper>
									);
								})}
							</Box>
						)}
					</>
				)}
			</Box>
		</>
	);
};
