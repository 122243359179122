import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { instance } from "../../../apis/baseApi";
import waiterLogo from "./waitIcon.svg";

interface DrugExtractionForChatProps {
	setMessages: Function;
	messages: any;
	setBotMessageCallback: Function;
	sentence: string;
	messagesEndRef: any;
}

export const DrugExtractionForChat: React.FC<DrugExtractionForChatProps> = ({
	setMessages,
	messages,
	setBotMessageCallback,
	sentence,
	messagesEndRef,
}: DrugExtractionForChatProps) => {
	const [drugsAnswer, setdrugsAnswer] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isExcelUnderFilling, setIsExcelUnderFilling] = useState<boolean>(false);
	const [excelFilled, setExcelFilled] = useState<boolean>(false);
	const apiCall = "/v1/drugs";
	const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
	const sleep = async () => {
		await delay(3000);
	};
	useEffect(() => {
		if (!isLoading) {
			scrollToBottom();
			return;
		}
		var inMemoryArray = [];
		messages.toReversed().map((m: any, index: number) => {
			let lookAtMsg = "";
			if (inMemoryArray.length >= 6 || !m.inMemory) {
				return;
			}
			if (m.type === "bot") {
				lookAtMsg = m.generatedMessage;
			} else {
				lookAtMsg = m.message;
			}
			if (lookAtMsg === sentence) {
				return;
			}

			inMemoryArray.push({
				author: m.type === "bot" ? "assistant" : "user",
				content: m.type === "bot" ? m.generatedMessage.answer : m.message,
			});
		});
	}, [isLoading]);

	const scrollToBottom = () => {
		if (messagesEndRef.current === null) {
			return;
		}
		messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const cleanExcel = async () => {
		await Excel.run(async (context: any) => {
			const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
			const lastRow = currentWorksheet.getUsedRange().getLastRow();
			lastRow.load("rowIndex"); // Load the rowIndex property of the last row

			await context.sync(); // Sync to get the last row's rowIndex

			const endRow = lastRow.rowIndex;
			const rangeAddress = `G6:I${endRow}`;
			const range = currentWorksheet.getRange(rangeAddress);

			// Load the background color and values of the range
			range.load(["values", "format/fill/color"]);
			await context.sync();
			const cells = range.getCellProperties({ format: { fill: { color: true } } });
			await context.sync();
			// Define the orange color in hexadecimal (e.g., "#FFA500" for orange)
			const orangeColor = "#FFA500".toLowerCase();

			// Loop through the range and check the fill color
			const values = range.values;

			for (let i = 0; i < values.length; i++) {
				for (let j = 0; j < values[i].length; j++) {
					const cellColor = cells.value[i][j].format.fill.color;
					range.getCell(i, j).values = [[""]]; // Clear the value
					if (cellColor && cellColor.toLowerCase() === orangeColor) {
						range.getCell(i, j).format.fill.clear(); // Clear the fill color
					}
				}
			}

			await context.sync();
		});
	};

	const fillExcel = async () => {
		await Excel.run(async (context: any) => {
			const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
			if (drugsAnswer === null || Object.keys(drugsAnswer).length === 0) {
				return;
			}
			drugsAnswer?.drugs.map(async (el: any) => {
				if (el?.drug?.production_address === null || el?.drug?.production_address === "") {
					currentWorksheet.getRange(`G${el.row + 1}`).format.fill.color = "orange";
				} else {
					currentWorksheet.getRange(`G${el.row + 1}`).values = [
						[el.drug.production_address],
					];
				}
				if (el?.drug?.vendor_address === null || el?.drug?.vendor_address === "") {
					currentWorksheet.getRange(`H${el.row + 1}`).format.fill.color = "orange";
				} else {
					currentWorksheet.getRange(`H${el.row + 1}`).values = [[el.drug.vendor_address]];
				}
				/*
				if (el?.drug?.iso === null || el?.drug?.iso === "") {
					currentWorksheet.getRange(`I${el.row + 1}`).format.fill.color = "orange";
				} else {
				*/
				var range = currentWorksheet.getRange(`I${el.row + 1}`);
				range.values = [
					[
						[
							"ISO 50001:2018, 22 November 2023 until 21 November 2026",
							"ISO 14001:2015, 22 November 2023 until 21 November 2026",
						].join("\n\n"),
					],
				];
				// }
				await context.sync();
				setExcelFilled(true);
				setIsExcelUnderFilling(false);
			});
		});
	};

	const extractDrugFromFile = async () => {
		setIsLoading(true);

		instance
			.get(apiCall)
			.then((response: any) => {
				if (response.status === 200) {
					setdrugsAnswer(response.data);
				}
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (isExcelUnderFilling) {
			sleep().then(() => {
				fillExcel();
			});
		}
	}, [drugsAnswer]);

	useEffect(() => {
		extractDrugFromFile().then(() => {
			setBotMessageCallback({
				answer: `Extracted ${(drugsAnswer as any)?.count} / ${
					(drugsAnswer as any)?.total
				} drugs`,
			});
			setIsExcelUnderFilling(true);
		});
	}, []);

	return (
		<>
			{drugsAnswer && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						p: 1,
						overflowY: "overlay",
					}}
				>
					<Typography variant="body1" sx={{ mb: 2, fontSize: "14px" }}>
						{(drugsAnswer as any)?.count} / {(drugsAnswer as any)?.total} médicaments
						extraits
					</Typography>
					{excelFilled && (
						<Typography variant="body1" sx={{ mb: 2, fontSize: "14px" }}>
							La feuille Excel a été remplie avec succès. <br />
							<br />
							<i>
								Veuillez vérifier les données avant d'envoyer afin de valider les
								données.
							</i>
						</Typography>
					)}
					{isExcelUnderFilling && (
						<Box sx={{ display: "block" }}>
							<Typography variant="body1" sx={{ mb: 2, fontSize: "14px" }}>
								Remplissage des cellules...
							</Typography>
							<img
								src={waiterLogo}
								alt="waiter"
								style={{
									width: "30px",
									height: "30px",
									margin: "auto",
								}}
							/>
						</Box>
					)}
				</Box>
			)}
			{!drugsAnswer && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						p: 2,
					}}
				>
					{isLoading && (
						<Box sx={{ display: "flex" }}>
							<img
								src={waiterLogo}
								alt="waiter"
								style={{
									width: "30px",
									height: "30px",
									margin: "auto",
								}}
							/>
						</Box>
					)}
				</Box>
			)}
			{excelFilled && (
				<Box
					sx={{
						mt: "-22px",
						display: "flex",
						justifyContent: "right",
						alignItems: "right",
					}}
				>
					<IconButton
						sx={{}}
						onClick={() => {
							cleanExcel();
						}}
					>
						<ThumbUp
							sx={{
								width: "20px",
							}}
						/>
					</IconButton>
					<IconButton
						onClick={() => {
							cleanExcel();
						}}
					>
						<ThumbDown
							sx={{
								width: "20px",
							}}
						/>
					</IconButton>
				</Box>
			)}
		</>
	);
};
