import { Avatar, Box, Button, Input } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { initFlow } from "../../apis/assistant";
import "./assistant.css";
import { DrugExtractionForChat } from "./widgets/fillExcel";
import { GraphDisplay } from "./widgets/graphDisplay";
import { People } from "./widgets/people";
import { Prediction } from "./widgets/prediction";

enum MessageType {
	bot = "bot",
	user = "user",
}

interface Message {
	message: any;
	type: MessageType;
	loading: boolean;
	withAvatar: boolean;
}

interface BotMessageProps {
	message: Message;
}

const BotMessage = ({ message }: BotMessageProps) => {
	return (
		<Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mb: 1 }}>
			<Box
				className="react-chatbot-kit-chat-bot-message-container"
				sx={{ display: "flex", justifyContent: "flex-start" }}
			>
				<Avatar
					src="/assets/icons/icon_16.png"
					alt="SecondBrain logo"
					sx={{ width: 33, height: 33, marginTop: "3px", marginLeft: "2px" }}
				/>
				<Box
					className="react-chatbot-kit-chat-bot-message"
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						ml: 1,
						backgroundColor: "#ccffd3",
						color: "black",
					}}
				>
					<Box
						sx={{
							maxWidth: "100%",
						}}
					>
						{message.message}
					</Box>
					<Box
						className="react-chatbot-kit-chat-bot-message-arrow"
						sx={{
							borderRightColor: "#ccffd3",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const UserMessage = ({ message }: any) => {
	return (
		<Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb: 2 }}>
			<Box
				className="react-chatbot-kit-user-chat-message-container"
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
					maxWidth: "70%",
				}}
			>
				<Box
					className="react-chatbot-kit-user-chat-message"
					sx={{
						backgroundColor: "secondary.light",
					}}
				>
					<Box
						sx={{
							maxWidth: "100%",
							color: "black",
							fontSize: "14px",
						}}
					>
						{message.message}
					</Box>
					<Box
						sx={{
							borderBottom: "8px solid transparent",
							borderLeft: "8px solid",
							borderTop: "8px solid transparent",
							height: 0,
							right: "-7px",
							position: "absolute",
							top: "13px",
							width: 0,
							borderLeftColor: "secondary.light",
						}}
					/>
				</Box>
				<Avatar
					src="/assets/icons/icon_16.png"
					alt="SecondBrain logo"
					sx={{ width: 33, height: 33, marginTop: "3px", marginLeft: "2px" }}
				/>
			</Box>
		</Box>
	);
};

interface AssistantProps {
	mode: string;
}

export const Assistant: React.FC<AssistantProps> = (props: AssistantProps) => {
	const [messages, setMessages] = useState<any[]>([]);
	const [userInput, setUserInput] = useState("");
	const [botMessageCallback, setBotMessageCallback] = useState<any>(null); // [message, setMessages
	const [performInMemory, setPerformInMemory] = useState(false); // [message, setMessages
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const addMessage = (message: any) => {
		setMessages((prev) => [...prev, message]);
	};

	const createBotMessage = (message: any, generatedMessage?: any, inMemory?: boolean) => {
		const botMessage = {
			generatedMessage: generatedMessage,
			message,
			inMemory: inMemory || false,
			type: "bot",
			loading: false,
			withAvatar: true,
		};
		setPerformInMemory(inMemory || false);
		addMessage(botMessage);
	};

	const createUserMessage = (message: any, inMemory?: boolean) => {
		const userMessage = {
			message,
			type: "user",
			inMemory: inMemory || false,
			loading: false,
			withAvatar: false,
		};
		addMessage(userMessage);

		if (isCommand(message)) {
			defineCommand(message);
			return;
		}
		initFlow(message)
			.then((res: any) => {
				defineResponse(res.data.message);
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	const resetMessages = () => {
		setMessages([]);
	};

	const defineCommand = (message: any) => {
		switch (message) {
			case "clear":
			case "/clear":
			case "reset":
			case "/reset":
				sendHello();
				break;
			case "/help":
			case "help":
			case "/help me":
				createBotMessage(
					"Vous pouvez me poser n'importe quelle question, je vais essayer de vous aider. Si vous voulez réinitialiser notre conversation, tapez simplement '/reset'."
					// "You can ask me anything, I will try to help you. If you want to reset our conversation, just type '/reset'."
				);
				break;
		}
	};

	const defineResponse = (message: any) => {
		switch (message) {
			case "welcome":
				createBotMessage(
					"Vous pouvez me poser n'importe quelle question, je vais essayer de vous aider. Si vous voulez réinitialiser notre conversation, tapez simplement '/reset'."
					//"You can ask me anything, I will try to help you. If you want to reset our conversation, just type '/reset'."
				);
				break;
			case "getUser":
				createBotMessage(<People {...message} />, message);
				break;
			case "graph":
				createBotMessage(
					<GraphDisplay
						setMessages={setMessages}
						messages={messages}
						setBotMessageCallback={setBotMessageCallback}
						sentence={message}
					/>,
					message
				);
				break;
			case "aiTourFlow-1":
				createBotMessage(
					<Prediction
						setMessages={setMessages}
						messages={messages}
						setBotMessageCallback={setBotMessageCallback}
						sentence={message}
						messagesEndRef={messagesEndRef}
						aiTour={true}
					/>,
					message,
					true
				);
				break;
			case "aiTourFlow-2":
				createBotMessage(
					<DrugExtractionForChat
						setMessages={setMessages}
						messages={messages}
						setBotMessageCallback={setBotMessageCallback}
						sentence={message}
						messagesEndRef={messagesEndRef}
					/>,
					message,
					true
				);
				break;
			case "askingForPeronalNews":
			case "getWeather":
			case "getLocation":
			case "getMapWay":
			case "getCalendar":
			case "findAPlaceInMyAgenda":
			case "freeMyCalendar":
			case "SumUpMyDay":
			case "remindTasks":
			case "actionTasks":
			case "sendEmail":
			case "findEmail":
			case "deleteEmail":
			case "findContact":
			case "addContact":
			case "deleteContact":
			default:
				createBotMessage(
					<Prediction
						setMessages={setMessages}
						messages={messages}
						setBotMessageCallback={setBotMessageCallback}
						sentence={message}
						messagesEndRef={messagesEndRef}
					/>,
					message,
					true
				);
			//call the api to get the prediction
		}
	};

	const isCommand = (message: any) => {
		if (message.startsWith("/") || message == "help") {
			return true;
		}
		return false;
	};

	const sendHello = () => {
		resetMessages();
		createBotMessage(
			"Bonjour, heureux de voir aujourd'hui. Comment puis-je vous aider ?"
			// "Hello you, happy to see today. Please pick an option or ask your question."
		);
	};

	useEffect(() => {
		if (botMessageCallback) {
			messages[messages.length - 1].generatedMessage = botMessageCallback;
			setPerformInMemory(false);
			setMessages(messages);
			setBotMessageCallback(null);
		}
	}, [botMessageCallback]);

	useEffect(() => {
		if (messages.length === 0) {
			sendHello();
		}
	}, []);

	const scrollToBottom = () => {
		if (messagesEndRef.current === null) {
			return;
		}
		messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		if (
			messages.length &&
			messages[messages.length - 1].inMemory &&
			messages[messages.length - 1].type === "bot" &&
			performInMemory
		) {
			messages[messages.length - 2].inMemory = true;
			setMessages(messages);
			return;
		} else if (performInMemory) {
			setPerformInMemory(false);
		}
		scrollToBottom();
	}, [messages]);

	return (
		<>
			<Box
				className="react-chatbot-kit-chat-container"
				component={"div"}
				sx={{
					height: "100%",
					width: "100%",
					position: "relative",
					overflow: "auto",
					overflowAnchor: "none",
				}}
			>
				{messages.map((message: any, i: number) => {
					if (message.type === MessageType.bot) {
						return <BotMessage key={i} message={message} />;
					} else if (message.type === MessageType.user) {
						return <UserMessage key={i} message={message} />;
					}
				})}
				<div ref={messagesEndRef} />
			</Box>
			<Box
				sx={{
					width: "100%",
					position: "relative",
					bottom: 0,
					borderTop: "1px solid #a5a5a5",
				}}
			>
				{/* Add the send part for the bot*/}
				<Input
					id="outlined-send-bot"
					value={userInput}
					placeholder="Write your message here"
					onChange={(e) => setUserInput(e.target.value)}
					sx={[
						{
							width: "calc(100% - 75px)",
							height: "100%",
							borderRadius: "0px",
							backgroundColor: "white",
							borderBottom: "0px !important",
						},
						{
							"&:before": {
								borderBottom: "0px !important",
							},
							"&:after": {
								borderBottom: "0px !important",
							},
							"&:hover": {
								borderBottom: "0px !important",
							},
							input: {
								borderBottom: "0px !important",
								marginLeft: "5px",
								marginRight: "5px",
							},
						},
					]}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							if (userInput === "") {
								return;
							}
							createUserMessage(userInput);
							setUserInput("");
						}
					}}
				/>
				<Button
					variant="contained"
					onClick={() => {
						createUserMessage(userInput);
						setUserInput("");
					}}
					sx={{
						height: "100%",
						width: "75px",
						borderRadius: "0px",
					}}
				>
					Send
				</Button>
			</Box>
		</>
	);
};
